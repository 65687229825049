.editor_page
{
    margin     : auto;
    width      : 95%;
    display    : flex;
    column-gap : 8px;
}

.history_card
{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    /* border-right: 1px solid gray; */
}

.cards_container
{
    flex        : 6;
    padding-top : 20px;
    display     : flex;
    column-gap  : 8px;
}

.training_card
{
    /* display: flex; */
    flex: 1;
    border: 1px solid rgb(162, 159, 159);
    /* padding: 5px; */
    background-color: rgb(246, 251, 251);
}

.training_card_max
{
    /* display: flex; */
    flex: 1;
    border: 1px solid rgb(162, 159, 159);
    /* padding: 5px; */
    background-color: rgb(246, 251, 251);
}

.content_card
{
    /* border: 1px solid gray; */
    background-color: rgb(246, 251, 251);
    padding: 10px;
    width: 100%;
    min-height: 300px;
    /* min-width: 150px; */
    /* flex: 1; */
}

.content_card_max
{
    font-size: large;
    background-color: rgb(246, 251, 251);
    padding: 10px;
    width: 100%;
    min-height: 300px;
    /* min-width: 150px; */
    /* flex: 1; */
}

.editor_card
{
    border-left      : 1px solid lightgray;
    display          : flex;
    flex-direction   : column;
    align-items      : center;
    padding          : 10px;
    min-width        : 250px;
    row-gap          : 15px;
    background-color : azure;
}

.editor_card_max
{
    border-left      : 1px solid lightgray;
    display          : flex;
    flex-direction   : column;
    align-items      : center;
    padding          : 10px;
    min-width        : 400px;
    row-gap          : 15px;
    background-color : azure;
}

.container div
{
    /* border: 1px solid black; */
}

button
{
    background: transparent;
    /* margin-left: 40px; */
    /* float: right; */
}

@media only screen and (max-width: 900px) 
{
    .editor_page
    {
        margin     : auto;
        width      : 95%;
        display    : flex;
        flex-direction: column;
        column-gap : 8px;
    }

    .history_card
    {
        padding-top: 5px;

        display: grid;
        grid-template-columns: 1fr 1fr;
        place-items: center;
        row-gap: 1px;
    }
    
    .cards_container
    {
        padding: 10px;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
    }

    .editor_card
    {
        padding: 10px;
        /* min-width: 300px; */
        width: 90%;
    }
}
