.App 
{
  /* text-align: center; */
}

header 
{
    display          : flex;
    align-items      : center;
    background-color : rgb(249, 182, 94);
    color            : white;
    padding          : 15px;
    margin           : auto;
    width            : 90%;
}

.logo 
{
    flex             : 3;
    align-items      : center;
    /* color            : white; */
    color            : rgb(231, 24, 115);
    font-size        : 38px;
    padding          : 10px 30px;
}

.logo_subtitle
{
    font-size   : 16px;
    margin-left : 20px;
    margin-top  : 5px;
    color       : #eec75e;
}

.topnav 
{
    flex            : 1;
    display         : flex;
    justify-content : center;
    overflow        : hidden;
    padding         : 10px;
    font-size       : 18px;
}

.topnav a
{
    color           : White;
    text-align      : center;
    padding         : 6px 16px;
    text-decoration : none;
}

.topnav a:hover 
{
  /* color: #eec75e; */
  color            : rgb(231, 24, 115);
  cursor: pointer;
}

.topnav a.active 
{
    color            : rgb(231, 24, 115);
    border-bottom: 2px solid rgb(231, 24, 115);
    /* color: #eec75e;
     border-bottom: 2px solid #eec75e; */
}

.topnav div.no_active 
{
    color: White;
}

table
{
    border-collapse: collapse;
    border:1px solid gray;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    /* table-layout: auto; */
}

th
{
    border-collapse: collapse;
    border:1px solid gray;
    padding-left: 3px;
    padding-right: 3px;
    background-color: lightgray;
    color: black;
    font-size: 15px;
    font-weight: normal;
}

.th_green
{
    border-collapse: collapse;
    border:1px solid gray;
    padding-left: 3px;
    padding-right: 3px;
    background-color: rgb(138, 214, 25);
    color: black;
    font-size: 15px;
    font-weight: normal;
}

td 
{
    border-collapse: collapse;
    border:1px solid gray;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    color: black;
}

button 
{
    background-color : white;
    border           : none;
    color            : blue;
    /* padding          : 5px; */
    text-align       : center;
    text-decoration  : none;
    font-family      : Verdana, Arial, sans-serif;
    font-size        : 14px;
    margin           : 5px;
    cursor           : pointer;
}

button:hover
{
    text-decoration: underline;
}

.action_button
{
    background-color : lightgray;
    border           : none;
    border-radius    : 5px;
    color            : rgb(43, 41, 41);
    padding          : 5px;
    text-align       : center;
    text-decoration  : none;
    display          : inline-block;
    font-family      : Verdana, Arial, sans-serif;
    font-size        : 14px;
    margin           : 4px 2px;
    cursor           : pointer;
    font-weight      : 400;
    min-width        : 60px;
}

.action_button:hover
{
    background-color : rgb(85, 82, 82);
    color            : white;
    text-decoration: underline;
}

@media only screen and (max-width: 900px) 
{
	header 
	{
        flex-direction : column;
        font-size      : 30px;
        padding : 1px;
	}

    .logo
    {
        font-size : 26px;
        padding   : 5px 10px;
    }

    .topnav 
    {
        padding         : 1px;
    }

    .topnav div 
    {
        padding         : 1px 1px;
        text-decoration : none;
    }
}
