* 
{
	box-sizing: border-box;
    padding: 0px;
}

body 
{
    font-family      : Verdana, Arial, sans-serif;
    font-size        : 16px;
    /* letter-spacing   : 1px; */
    line-height      : 1.5;
    color            : #5A5A64;
    /* background-color : #F4F4F4; */
    padding          : 0px;
    margin           : 0px auto;
    /* width            : 90%; */
    /* max-width        : 1400px; */
}

code 
{
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (max-width: 900px) 
{
    body 
    {
        width : 100%;
    }
}
